<template>
  <div
    style="
      width: 100%;
      min-height: 600px;
      overflow: hidden;
      padding: 40px;
      background: #fff;
    "
  >
    <div style="border: 1px solid #e2e2e2">
      <ul style="background-color: #f4f9fd; padding: 10px">
        <text>尊敬的用户，您好！</text>
        <p>
          如果您对本站有任何建议意见，欢迎您留言。我们感谢您的意见和建议，谢谢！
        </p>
      </ul>
      <el-form
        :model="state.model"
        label-width="auto"
        style="max-width: 600px; padding: 10px"
        ref="formDom"
      >
        <el-form-item
          label="主题"
          prop="Title"
          :rules="[
            {
              required: true,
              message: '请输入主题',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="state.model.Title" />
        </el-form-item>
        <el-form-item
          label="电子邮箱"
          prop="Email"
          :rules="[
            {
              required: true,
              message: '请输入电子邮箱',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="state.model.Email" />
        </el-form-item>
        <el-form-item label="电话号码">
          <el-input v-model="state.model.Phone" />
        </el-form-item>
        <el-form-item
          label="留言内容"
          prop="Content"
          :rules="[
            {
              required: true,
              message: '请输入电子内容',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            v-model="state.model.Content"
            type="textarea"
            :autosize="{ minRows: 6 }"
          />
        </el-form-item>
        <el-form-item
          label="验证码"
          prop="Validate"
          :rules="[
            {
              required: true,
              message: '请输入验证码',
              trigger: 'blur',
            },
          ]"
        >
          <el-input v-model="state.model.Validate" style="width: 100px" />
          <el-image
            :src="'data:image/png;base64,' + state.image"
            @click="onLoadValidate()"
          />
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" @click="onSubmit">提交</el-button>
          <el-button>重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { home_buildvalidate, home_addwords } from "@/http/api";
import { ElMessage } from "element-plus";
export default {
  setup() {
    const formDom = ref(null);
    const state = reactive({
      model: {},
    });
    onMounted(() => {
      onLoadValidate();
    });

    const onLoadValidate = () => {
      home_buildvalidate().then((res) => {
        console.log(res.data);

        if (res.status == 200) state.image = res.data;
      });
    };

    const onSubmit = () => {
      formDom.value.validate((valid) => {
        if (valid) {
          home_addwords(state.model)
            .then((res) => {
              if (res.status == 200) {
                ElMessage.success(res.data);
                state.model = {};
                onLoadValidate();
              } else {
                ElMessage.error(res.data);
              }
            })
            .catch((err) => {
              ElMessage.error(err.response.data);
            });
        }
      });
    };
    return { state, formDom, onSubmit, onLoadValidate };
  },
};
</script>
<style scoped></style>
