<template>
  <div style="width: 1200px; margin: 0 auto">
    <el-image
      :src="$http.defaults.baseURL + state.config.DetailImg"
      style="width: 100%; height: 100%"
    />
    <div style="margin: 5px 0 10px 0; display: flex; align-items: center">
      您当前的位置：首页>
      <li v-for="(nav, index) in state.navs" :key="nav">
        <text v-if="index != 0">></text>
        {{ nav.Name }}
      </li>
    </div>
    <div style="display: flex" v-if="state.fMenu">
      <div style="width: 250px">
        <ul
          style="
            background: -webkit-gradient(
              linear,
              0 0,
              0 bottom,
              from(#4fa2d9),
              to(rgba(50, 112, 181, 1))
            );
            text-align: center;
            height: 40px;
            line-height: 40px;
            color: #fff;
          "
        >
          {{
            state.fMenu?.Name
          }}
        </ul>
        <div
          v-for="child in state.menus"
          :key="child"
          style="border-bottom: 1px dashed #000"
        >
          <ul
            class="menu"
            style="
              text-align: center;
              height: 40px;
              line-height: 40px;
              cursor: pointer;
              position: relative;
            "
            :class="state.selectId == child.Id ? 'menu-select' : ''"
            @click.stop="onMenuClick(child)"
          >
            {{
              child.Name
            }}
            <el-icon
              v-if="child.Children?.length > 0"
              color="#a2a2a2"
              style="position: absolute; top: 10px; right: 10px"
              ><CaretBottom
            /></el-icon>
          </ul>
          <div
            style="height: 0; overflow: hidden; transition: height 0.3s ease"
            :data-id="child.Id"
            ref="thirdMenu"
          >
            <ul
              v-for="child2 in child.Children"
              :key="child2"
              class="menu"
              @click.stop="onMenuClick(child2)"
              style="
                font-size: 15px;
                text-align: center;
                line-height: 28px;
                cursor: pointer;
              "
              :class="state.selectId == child2.Id ? 'menu-select' : ''"
            >
              {{
                child2.Name
              }}
            </ul>
          </div>
        </div>
      </div>
      <div
        style="flex: 1; margin-left: 20px; background: #fff; overflow: hidden"
        v-if="state.model.Template"
      >
        <c_list2 v-if="state.model.Template == 2" :menu="state.model"></c_list2>
        <c_list3
          v-else-if="state.model.Template == 3"
          :menu="state.model"
        ></c_list3>
        <c_list4
          v-else-if="state.model.Template == 4"
          :menu="state.model"
        ></c_list4>
        <c_list5
          v-else-if="state.model.Template == 5"
          :menu="state.model"
        ></c_list5>
        <c_words v-else-if="state.model.Template == 8"></c_words>
        <div
          v-else
          v-html="state.model.Content"
          style="background-color: #fff; padding: 10px"
          class="contentBox"
        ></div>
      </div>
      <div v-else style="margin-left: 20px; color: #a2a2a2; padding: 20px">
        数据加载中……
      </div>
    </div>
    <div
      v-else
      v-loading="true"
      :element-loading-spinner="svg"
      element-loading-svg-view-box="-10, -10, 50, 50"
      style="width: 100%; height: 600px; background-color: rgba(0, 0, 0, 0.2)"
    ></div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from "vue";
import { home_getconfig, home_getnewsbymenuid } from "@/http/api";
import { useRoute, useRouter } from "vue-router";

import list2 from "./List2.vue";
import list3 from "./List3.vue";
import list4 from "./List4.vue";
import list5 from "./List5.vue";
import words from "./Words.vue";

export default {
  components: {
    c_list2: list2,
    c_list3: list3,
    c_list4: list4,
    c_list5: list5,
    c_words: words,
  },
  setup(props) {
    const thirdMenu = ref([]);
    const route = useRoute();
    const router = useRouter();
    const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;
    const state = reactive({
      config: {},
      model: {},
    });
    onMounted(() => {
      home_getconfig().then((res) => {
        if (res.status == 200) state.config = res.data;
      });
      home_getnewsbymenuid(route.params.id).then((res) => {
        if (res.status == 200) {
          const { navs, fMenu, menus, model } = res.data;
          state.navs = navs;
          state.fMenu = fMenu;
          state.menus = menus;
          state.model = model;
          state.selectId = model.Id;

          setTimeout(() => {
            menus.forEach((e) => {
              if (e.Children.some((w) => w.Id == model.Id)) {
                onSecondMenuChange(e);
                return false;
              }
            });
          }, 200);
        }
      });
    });

    watch(
      () => route.params.id,
      (newid) => {
        home_getnewsbymenuid(newid).then((res) => {
          if (res.status == 200) {
            const { navs, fMenu, menus, model } = res.data;
            state.navs = navs;
            state.fMenu = fMenu;
            state.menus = menus;
            state.model = model;
            state.selectId = model.Id;

            setTimeout(() => {
              menus.forEach((e) => {
                if (e.Children.some((w) => w.Id == model.Id)) {
                  onSecondMenuChange(e);
                  return false;
                }
              });
            }, 200);
          }
        });
      }
    );
    const onSecondMenuChange = (child) => {
      console.log(child);

      let dom;
      thirdMenu.value.forEach((e) => {
        let id = e.getAttribute("data-id");

        if (id == child.Id) {
          dom = e;
          return false;
        }
      });

      setTimeout(() => {
        dom.style.height = child.Children?.length * 28 + "px";
      }, 200);
    };

    const onMenuClick = (menu) => {
      if (menu.Children.length > 0) {
        const currMenu = menu.Children[0];
        menu = currMenu;
      }

      if (menu.Template == 7) {
        const url = menu.LinkUrl;
        if (url.includes("http://") || url.includes("https://"))
          window.open(url, "_blank");
        else window.open("http://" + url, "_blank");
      } else {
        router.push(`/main/m/${menu.Id}`);
      }
    };
    return { state, props, thirdMenu, onSecondMenuChange, onMenuClick, svg };
  },
};
</script>
<style lang="less" scoped>
:deep(.contentBox img) {
  max-width: 100%;
}
</style>
