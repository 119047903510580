<template>
  <div style="width: 100%; min-height: 600px; overflow: hidden; padding: 10px">
    <div style="background: #fff">
      <ul
        style="
          background-color: red;
          color: #fff;
          text-align: center;
          letter-spacing: 5px;
          line-height: 30px;
          font-size: 18px;
        "
      >
        {{
          props.menu?.Name
        }}
      </ul>
      <div
        style="display: flex; flex-wrap: wrap; margin-top: 20px; padding: 30px"
      >
        <div
          style="width: 31%; margin-bottom: 20px"
          :style="{ marginLeft: index % 3 != 0 ? '3.4%' : '' }"
          v-for="(news, index) in state.tableData"
          :key="news"
          @click="onNewsClick(news)"
        >
          <el-image
            :src="$http.defaults.baseURL + news.FirstImg"
            style="width: 100%; height: 10vw"
          />
          <ul style="text-align: center">
            {{
              news.Title
            }}
          </ul>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 20px">
      <el-pagination
        small
        background
        layout="prev, pager, next"
        :total="state.totalCount"
        :default-page-size="state.params.PageSize"
        prev-text="上一页"
        next-text="下一页"
        :pageIndex="state.params.PageIndex"
        @current-change="
          (pageIndex) => {
            state.params.PageIndex = pageIndex;
            onLoad();
          }
        "
      />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, watch } from "vue";
import { home_getnewspage } from "@/http/api";
import { useRouter } from "vue-router";
export default {
  props: {
    menu: Object,
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 12,
        PageIndex: 1,
        Params: {},
      },
    });
    onMounted(() => {
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      home_getnewspage(props.menu?.Id, state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    watch(
      () => props.menu?.Id,
      (newid) => {
        delete state.tableData;
        home_getnewspage(newid, state.params).then((res) => {
          if (res.status == 200) {
            const { count, list } = res.data;
            state.tableData = list;
            state.totalCount = count;
          }
        });
      }
    );

    const onNewsClick = (item) => {
      if (item.IsLink) {
        if (item.LinkUrl) {
          const url = item.LinkUrl;
          if (url.includes("http://") || url.includes("https://"))
            window.open(url, "_blank");
          else window.open("http://" + url, "_blank");
        }
      } else router.push("/main/c/" + item.Id);
    };

    return { state, props, onNewsClick, onLoad };
  },
};
</script>
<style scoped></style>
