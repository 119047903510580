<template>
  <div style="width: 100%; min-height: 600px; overflow: hidden">
    <div style="background-color: #fff; padding: 10px">
      <ul style="display: flex; align-items: center">
        <div style="flex: 1">
          <span
            style="
              display: inline-block;
              padding-left: 5px;
              letter-spacing: 5px;
              text-align: center;
              line-height: 30px;
              font-size: 18px;
              background: -webkit-gradient(
                linear,
                0 0,
                0 bottom,
                from(#4fa2d9),
                to(rgba(50, 112, 181, 1))
              );
              color: #fff;
            "
          >
            {{ props.menu?.Name }}
          </span>
        </div>
        <el-input
          v-model="state.params.Params.Key"
          placeholder="关键字"
          suffix-icon="search"
          style="width: 200px"
        />
      </ul>
      <div
        style="
          width: 100%;
          overflow: hidden;
          margin-top: 10px;
          padding: 0 20px;
          cursor: pointer;
        "
      >
        <template v-if="state.tableData?.length > 0">
          <div
            v-for="news in state.tableData"
            :key="news"
            @click="onNewsClick(news)"
            style="border-bottom: 1px dashed grey"
          >
            <ul style="display: flex; align-items: center">
              <li
                style="
                  width: 7px;
                  height: 7px;
                  border-radius: 50%;
                  background-color: #3881da;
                "
              ></li>
              <div class="list1-news-title">
                <li>{{ news.Title }}</li>
                <text>{{ $dayjs(news.PublishTime).format("YYYY-MM-DD") }}</text>
              </div>
            </ul>
            <ul
              style="
                margin-bottom: 10px;
                color: #a2a2a2;
                max-height: 60px;
                padding-left: 20px;
              "
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
              {{
                news.Summary
              }}
            </ul>
          </div>
        </template>
        <text v-else style="color: #a2a2a2; margin: 20px">暂无相关内容~</text>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 20px">
      <el-pagination
        small
        background
        layout="prev, pager, next"
        :total="state.totalCount"
        :default-page-size="state.params.PageSize"
        prev-text="上一页"
        next-text="下一页"
        :pageIndex="state.params.PageIndex"
        @current-change="
          (pageIndex) => {
            state.params.PageIndex = pageIndex;
            onLoad();
          }
        "
      />
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, onUnmounted, watch } from "vue";
import { home_getnewspage } from "@/http/api";
import { useRouter } from "vue-router";
export default {
  props: {
    menu: Object,
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      params: {
        PageSize: 20,
        PageIndex: 1,
        Params: {},
      },
    });
    onMounted(() => {
      //绑定监听事件
      window.addEventListener("keydown", keyDown);
      onLoad();
    });

    const onLoad = () => {
      delete state.tableData;
      home_getnewspage(props.menu?.Id, state.params).then((res) => {
        if (res.status == 200) {
          const { count, list } = res.data;
          state.tableData = list;
          state.totalCount = count;
        }
      });
    };

    watch(
      () => props.menu?.Id,
      (newid) => {
        delete state.tableData;
        home_getnewspage(newid, state.params).then((res) => {
          if (res.status == 200) {
            const { count, list } = res.data;
            state.tableData = list;
            state.totalCount = count;
          }
        });
      }
    );

    onUnmounted(() => {
      //销毁事件
      window.removeEventListener("keydown", keyDown, false);
    });

    //点击回车键登录
    const keyDown = (e) => {
      if (e.keyCode == 13) {
        onLoad();
      }
    };

    const onNewsClick = (item) => {
      if (item.IsLink) {
        if (item.LinkUrl) {
          const url = item.LinkUrl;
          if (url.includes("http://") || url.includes("https://"))
            window.open(url, "_blank");
          else window.open("http://" + url, "_blank");
        }
      } else router.push("/main/c/" + item.Id);
    };

    return { state, props, onNewsClick, onLoad };
  },
};
</script>
<style scoped lang="less">
.list1-news-title {
  flex: 1;
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
  overflow: hidden;

  & > li {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 50px;
  }
}
</style>
